<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <!-- <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div> -->
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-dept type="search" label="부서" name="deptCd" v-model="searchParam.deptCd" :isFirstValue="false" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사용여부 -->
          <c-select
            :comboItems="retireFlagItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="retireFlag"
            label="퇴직여부"
            v-model="searchParam.retireFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <c-table
          ref="userTable"
          title="사용자"
          tableId="userTable"
          :columns="grid.columns"
          :data="grid.data"
          @rowClick="rowClick"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="검색" icon="search" @btnClicked="getList" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <q-form ref="editForm">
          <c-card title="상세" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn
                  v-if="editable && deleteable"
                  :url="resetUrl"
                  :isSubmit="isReset"
                  :param="data"
                  mappingType="PUT"
                  label="비밀번호초기화"
                  icon="password"
                  @beforeAction="resetPwd"
                  @btnCallback="resetPwdCallback" />
                <c-btn v-if="editable" label="신규" icon="add" @btnClicked="addData" />
                <c-btn
                  v-if="editable && saveable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="saveType"
                  label="저장"
                  icon="save"
                  @beforeAction="saveData"
                  @btnCallback="saveCallback" />
                <!-- <c-btn
                  v-if="editable && deleteable"
                  :url="deleteUrl"
                  :isSubmit="isDelete"
                  :param="data"
                  mappingType="DELETE"
                  label="삭제"
                  icon="remove"
                  @beforeAction="deleteData"
                  @btnCallback="deleteCallback" /> -->
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-6">
                <c-text
                  :editable="false"
                  label="User Id"
                  name="userId"
                  v-model="data.userId">
                </c-text>
              </div>
              <div class="col-6">
                <c-text
                  :required="true"
                  :editable="editable && dataeditable"
                  label="사번"
                  name="empNo"
                  v-model="data.empNo">
                </c-text>
              </div>
              <div class="col-6">
                <c-text
                  :required="true"
                  :editable="editable && dataeditable"
                  label="사용자명"
                  name="userName"
                  v-model="data.userName">
                </c-text>
              </div>
              <div class="col-6">
                <c-text
                  :required="true"
                  :editable="editable && dataeditable"
                  label="로그인 ID"
                  name="loginId"
                  v-model="data.loginId">
                </c-text>
              </div>
              <div class="col-6">
                <c-dept :required="true" :editable="editable && dataeditable" type="edit" label="부서" name="deptCd" v-model="data.deptCd" :isFirstValue="false" />
              </div>
              <div class="col-6">
                <c-select
                  codeGroupCd="SEX_CD"
                  :editable="editable && dataeditable"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="sexCd"
                  label="성별"
                  v-model="data.sexCd"
                ></c-select>
              </div>
              <div class="col-6">
                <c-text
                  :editable="editable && dataeditable"
                  label="전화번호"
                  name="mobileNo"
                  v-model="data.mobileNo">
                </c-text>
              </div>
              <div class="col-6">
                <c-datepicker
                  :editable="editable && dataeditable"
                  label="생년월일"
                  name="birthDate"
                  type="date"
                  v-model="data.birthDate">
                </c-datepicker>
              </div>
              <div class="col-6">
                <c-text
                  :editable="editable && dataeditable"
                  label="이메일"
                  name="email"
                  type="email"
                  v-model="data.email">
                </c-text>
              </div>
              <div class="col-6">
                <c-select
                  :editable="editable && dataeditable"
                  codeGroupCd="JOB_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="jobCd"
                  label="직책"
                  v-model="data.jobCd"
                ></c-select>
              </div>
              <div class="col-6">
                <c-select
                  :editable="editable && dataeditable"
                  codeGroupCd="SPOT_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="spotCd"
                  label="직위"
                  v-model="data.spotCd"
                ></c-select>
              </div>
              <div class="col-6">
                <c-radio
                  :editable="editable && dataeditable"
                  :comboItems="col11Items"
                  label="퇴직여부"
                  name="retireFlag"
                  v-model="data.retireFlag">
                </c-radio>
              </div>
              <div class="col-6">
                <c-text
                  v-if="data.retireFlag == 'Y'"
                  :editable="editable && dataeditable"
                  label="퇴직일자"
                  name="retireDate"
                  type="date"
                  v-model="data.retireDate">
                </c-text>
              </div>
            </template>
          </c-card>
        </q-form>
      </div>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'user-manage',
  data() {
    return {
      resetUrl: '',
      isReset: false,
      searchParam: {
        plantCd: null,
        deptCd: '',
        retireFlag: 'N',
        innerFlag: 'Y'
      },
      retireFlagItems: [
        { code: 'N', codeName: '재직' },
        { code: 'Y', codeName: '퇴직' },
      ],
      grid: {
        columns: [
          {
            name: 'empNo',
            field: 'empNo',
            label: '사번',
            align: 'center',
            sortable: true,
          },
          {
            name: 'userName',
            field: 'userName',
            label: '사용자명',
            align: 'center',
            sortable: true,
          },
          {
            name: 'loginId',
            field: 'loginId',
            label: '로그인 ID',
            align: 'center',
            sortable: true,
          },
          {
            name: 'deptName',
            field: 'deptName',
            label: '부서명',
            align: 'center',
            sortable: true,
          },
          {
            name: 'sexName',
            field: 'sexName',
            label: '성별',
            align: 'center',
            sortable: true,
          },
          {
            name: 'retireFlag',
            field: 'retireFlag',
            label: '퇴직여부',
            align: 'center',
            style: 'width:70px',
            sortable: true,
          },
        ],
        data: [],
      },
      data: {
        companyCd: '',
        userId: '',
        empNo: '',
        userName: '',
        loginId: '',
        // loginPwd: '',
        deptName: '',
        sexCd: null,
        mobileNo: '',
        birthDate: '',
        email: '',
        jobCd: null,
        spotCd: null,
        retireFlag: 'N',
        managerFlag: 'N',
        retireDate: '',
        innerFlag: 'Y',
        regUserId: '',
        chgUserId: '',
        afterCnt: '',
      },
      col11Items: [
        { code: 'N', codeName: '재직' },
        { code: 'Y', codeName: '퇴직' },
      ],
      listUrl: '',
      detailUrl: '',
      checkUrl: '',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      saveable: false,
      deleteable: false,
      editable: true,
      dataeditable: false,
      updateMode: false,
      isSave: false,
      isDelete: false,
      checkUserUrl: '',
      saveUrl: transactionConfig.mdm.user.insert.url,
      saveType: 'POST',
      searchUrl: '',
      selectedUserId: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.dataeditable = false;
      // api scope
      this.listUrl = selectConfig.mdm.user.list.url;
      this.checkUserUrl = selectConfig.ten.company.check.user.url;
      this.detailUrl = selectConfig.mdm.user.get.url;
      this.checkUrl = selectConfig.mdm.user.check.loginid.url;
      this.insertUrl = transactionConfig.mdm.user.insert.url;
      this.updateUrl = transactionConfig.mdm.user.update.url;
      this.deleteUrl = transactionConfig.mdm.user.delete.url;
      this.resetUrl = transactionConfig.mdm.user.reset.url;
      this.getList();
    },
    rowRemoveSelect() {
      var _table = document.getElementsByClassName('bg-light-blue-1')[0];
      if (_table) {
        _table.classList.remove('bg-light-blue-1');
      }
    },
    getList() {
      this.rowNotSelected = true;
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        if (this.$store.getters.user.userId === 'U000000001') {
          this.grid.data = _result.data;
        } else {
          this.grid.data = this.$_.filter(_result.data, item => {return item['userId'] !== 'U000000001'});
        }
      },);
      this.saveable = false;
      this.deleteable = false;
      this.reset();
    },
    rowClick(row) {
      // 상세조회
      this.saveable = true;
      this.deleteable = true;
      this.rowNotSelected = false;
      this.updateMode = true;
      this.selectedUserId = row.userId;
      this.$http.url = this.$format(this.detailUrl, this.selectedUserId);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.data = _result.data;
        this.saveUrl = this.updateUrl;
        this.saveType = 'PUT';
        this.dataeditable = true;
        // this.data.loginPwd = '';
      },
      () => {
      });
    },
    resetPwd() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '비밀번호를 초기화 하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.data.chgUserId = this.$store.getters.user.userId
          this.isReset = !this.isReset;
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    resetPwdCallback() {
      window.getApp.$emit('ALERT', {
        title: '안내', // 안내
        message:
        '비빌번호가 초기화 되었습니다.',
        type: 'success', // success / info / warning / error
      });
    },
    addData() {
      this.rowRemoveSelect();
      this.saveable = true;
      this.deleteable = false;
      this.saveUrl = this.insertUrl;
      this.saveType = 'POST';
      this.updateMode = false;
      this.dataeditable = true;
      this.data = {
        companyCd: this.$store.getters.user.companyCd,
        userId: '',
        empNo: '',
        userName: '',
        loginId: '',
        // loginPwd: '',
        deptCd: '',
        sexCd: '',
        mobileNo: '',
        birthDate: '',
        email: '',
        jobCd: null,
        spotCd: null,
        retireFlag: 'N',
        managerFlag: 'N',
        retireDate: '',
        innerFlag: 'Y'
      };
    },
    reset() {
      this.rowRemoveSelect();
      this.saveable = false;
      this.deleteable = false;
      this.updateMode = false;
      this.dataeditable = false;
      this.data = {
        companyCd: this.$store.getters.user.companyCd,
        userId: '',
        empNo: '',
        userName: '',
        loginId: '',
        // loginPwd: '',
        deptCd: '',
        sexCd: null,
        mobileNo: '',
        birthDate: '',
        email: '',
        jobCd: null,
        spotCd: null,
        managerFlag: 'N',
        retireFlag: 'N',
        retireDate: '',
        innerFlag: 'Y'
      };
    },
    saveData() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          this.$http.url = this.checkUserUrl;
          this.$http.param = {
            companyCd: this.$store.getters.user.companyCd,
          }
          this.$http.type = 'GET';
          this.$http.request((_result2) => {
            if (Number(_result2.data['nowUserCnt']) < Number(_result2.data['maxUserCnt'])) {
              this.$http.url = this.checkUrl;
              this.$http.param = {
                loginId: this.data.loginId,
                empNo: this.data.empNo,
              }
              this.$http.type = 'GET';
              this.$http.request((_result3) => {
                if (this.updateMode || _result3.data === 0) {
                  window.getApp.$emit('CONFIRM', {
                    title: '확인',
                    message: '저장하시겠습니까?',
                    // TODO : 필요시 추가하세요.
                    type: 'info', // success / info / warning / error
                    // 확인 callback 함수
                    confirmCallback: () => {
                      if (this.saveType === 'POST') {
                        this.data.afterCnt = Number(_result2.data['nowUserCnt']) + 1
                      } else {
                        this.data.afterCnt = Number(_result2.data['nowUserCnt'])
                      }
                      this.data.companyCd = this.$store.getters.user.companyCd
                      this.data.regUserId = this.$store.getters.user.userId;
                      this.data.chgUserId = this.$store.getters.user.userId;
                      this.isSave = !this.isSave;
                    },
                    // 취소 callback 함수
                    cancelCallback: () => {
                    },
                  });
                } else {
                  window.getApp.$emit('ALERT', {
                    title: '안내', // 안내
                    message:
                    '동일한 사번 또는 로그인 ID가 존재합니다.',
                    type: 'warning', // success / info / warning / error
                  });
                  return;
                }
              },);
            } else {
              window.getApp.$emit('ALERT', {
                title: '안내', // 안내
                message:
                '등록할 수 있는 사용자는 최대 ' + _result2.data['maxUserCnt'] + '개 입니다.',
                type: 'warning', // success / info / warning / error
              });
              return;
            }
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    deleteData() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.data.userId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            this.isDelete = !this.isDelete;
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (this.saveType == 'PUT') {
        this.getList();
        this.rowClick({ userId: result.data.userId })
      } else {
        this.getList();
      }
    },
    deleteCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.reset();
      this.getList();
    },
  }
};
</script>
